<template>
  <div class="hidden-danger-bank flex">
    <div class="left flex-column">
      <div class="nameTit flex-center-v">
        <span>隐患</span>
      </div>
      <div class="cont flex-1-auto">
        <el-tree
            class="filter-tree"
            :data="dataTree"
            node-key="id"
            :props="defaultProps"
            default-expand-all
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            ref="tree"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span class="flex-center-v" v-if="isCompany&&(isTissueAdmin||isAdmin)">
              <el-button v-if="!data.children && data.status" type="text" size="mini" @click.stop="() => add(data)">新增隐患</el-button>
              <el-button v-if="!data.children && !data.status" type="text" size="mini" disabled>类别已作废</el-button>
              <!-- <el-button type="text" size="mini" @click.stop="() => edit(data)">编辑</el-button>-->
              <!-- <el-button type="text" size="mini" @click.stop="() => enableOrDisable(data, 'star')">启用</el-button>-->
              <!-- <el-button type="text" size="mini" @click.stop="() => enableOrDisable(data, 'end')">作废</el-button>-->
            </span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="right flex-column">
      <div class="nameTit flex-center-v">
        <span>隐患列表</span>
      </div>
      <div class="cont flex-1-auto">
        <el-table ref="multipleTable" :data="tableList" height="100%" style="width: 100%" tooltip-effect="light myTooltips">
          <el-table-column type="index" label="序号" align="center"></el-table-column>
          <el-table-column prop="checkContent" label="排查内容" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="rectifyRequirement" label="整改要求" align="center" show-overflow-tooltip></el-table-column>
          <el-table-column prop="rectifyLimitDay" label="整改时限(天)" align="center"></el-table-column>
          <el-table-column prop="dangerLevel" label="隐患级别" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.dangerLevel | turn2text(1) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="accidentDanger" label="事故隐患" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.accidentDanger | turn2text(2) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.createTime | cutTime }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="{ row }">
              <span>{{ row.status ? '启用中' : '作废中' }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" v-if="isCompany&&(isTissueAdmin||isAdmin)">
            <template slot-scope="{ row }">
              <el-button type="text" size="mini" @click="edit(row)">编辑</el-button>
              <el-button v-if="!row.status" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
              <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">作废</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex-center" style="padding-top: 10px">
        <el-pagination
            v-if="searchData.total > searchData.pageSize"
            background
            :current-page.sync="searchData.pageCurrent"
            :page-size="searchData.pageSize"
            :total="searchData.total"
            layout="total, prev, pager, next"
            @current-change="handleSizeChangeData"
        ></el-pagination>
      </div>
    </div>
    <el-dialog :title="title" :visible.sync="showTank" width="30%" :close-on-click-modal="false" @close="close">
      <el-form ref="form" :model="formData" :rules="rules" class="demo-form-inline" label-width="110px">
        <el-form-item label="隐患级别" prop="dangerLevel">
          <el-select v-model="formData.dangerLevel" placeholder="请选择隐患级别" size="small" style="width:100%;">
            <el-option label="一" :value="1"></el-option>
            <el-option label="二" :value="2"></el-option>
            <el-option label="三" :value="3"></el-option>
            <el-option label="四" :value="4"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事故隐患" prop="accidentDanger">
          <el-select v-model="formData.accidentDanger" placeholder="请选择事故隐患" size="small" style="width:100%;">
            <el-option label="一般" :value="1"></el-option>
            <el-option label="重大" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="整改时限(天)" prop="rectifyLimitDay">
          <el-input-number v-model="formData.rectifyLimitDay" controls-position="right" :min="1" :max="999" style="width:100%;"></el-input-number>
        </el-form-item>
        <el-form-item label="排查内容" prop="checkContent">
          <el-input type="textarea" placeholder="请输入排查内容" v-model="formData.checkContent" :rows="4" maxlength="255" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="整改要求" prop="rectifyRequirement">
          <el-input type="textarea" placeholder="请输入整改要求" v-model="formData.rectifyRequirement" :rows="4" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="save">确 定</el-button>
        <el-button size="small" @click="showTank = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import apis from './apis'
import {mapGetters} from "vuex";

export default {
  name: "hidden-danger-bank",

  components: {},

  props: {},

  data() {
    return {
      dataTree: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      title: '新增隐患',
      showTank: false,
      formData: {},
      rules: {
        dangerLevel: [
          { required: false, message: '请选择隐患级别', trigger: 'change' },
        ],
        accidentDanger: [
          { required: false, message: '请选择事故隐患', trigger: 'change' },
        ],
        rectifyLimitDay: [
          { required: true, message: '请输入整改时限', trigger: 'blur' },
        ],
        checkContent: [
          { required: true, message: '请输入排查内容', trigger: 'blur' },
        ],
        rectifyRequirement: [
          { required: false, message: '请输入整改要求', trigger: 'blur' },
        ],
      },
      tableList: [],
      searchData: {
        pageCurrent: 1,
        pageSize: 10,
        total: 0
      }
    }
  },

  computed: {
    ...mapGetters(['companyId','optionItem']),
    isTissueAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("组织管理员");
    },
    isAdmin() {
      return this.optionItem && this.optionItem.roleNameList && this.optionItem.roleNameList.includes("超级管理员")
    },
    // 是否为集团人员
    isCompany(){
      return this.optionItem && this.optionItem.departmentType == 'company';
    },
  },
  filters: {
    turn2text(value, type) {
      if (!value)return ''
      if (type == 1) {
        const data = [
          { value: 1 , text: '一' },
          { value: 2 , text: '二' },
          { value: 3 , text: '三' },
          { value: 4 , text: '四' }
        ]
        const find = data.find(item => {
          return item.value == value
        })
        return find.text
      } else {
        const data = [
          { value: 1 , text: '一般' },
          { value: 2 , text: '重大' }
        ]
        const find = data.find(item => {
          return item.value == value
        })
        return find.text
      }
    },
    cutTime(value) {
      if (!value)return ''
      return value.substring(0,10)
    }
  },
  watch: {
    companyId() {
      this.init()
    }
  },

  mounted() {
    if(this.companyId) this.init()
  },

  methods: {
    async init() {
      this.dataTree = await apis.getCategoryTree()
    },
    async getTableList() {
      const result = await apis.pageBank(this.searchData)
      this.tableList = result.list
      this.searchData.total = result.total
    },
    // 节点被点击事件
    async handleNodeClick(node) {
      this.searchData.categoryCode = node.code
      this.getTableList().async
    },
    close() {
      this.formData = {}
      this.$refs.form.resetFields()
    },
    save() {
      this.$refs.form.validate(async validate => {
        if (validate) {
          if(this.isEdit) await apis.editBank(this.formData)
          else await apis.addBank(this.formData)
          this.$message.success('操作成功')
          this.showTank = false
          this.getTableList().async
        }
      })
    },
    add(data) {
      console.log(data)
      this.formData.categoryCode = data.code
      this.title = '新增隐患'
      this.isEdit = false
      this.showTank = true
    },
    edit(data) {
      this.title = '编辑隐患'
      this.formData = JSON.parse(JSON.stringify(data))
      this.isEdit = true
      this.showTank = true
    },
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该隐患' : '是否作废该隐患'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await apis.enableBank(data.id)
        else await apis.disableBank(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getTableList().async
      })
    },
    // 切换页码
    async handleSizeChangeData(val) {
      this.searchData.pageCurrent = val
      this.getTableList().async
    }
  }
}
</script>

<style lang="stylus" scoped>
.hidden-danger-bank {
  height: 100%;
  .left {
    width 30%
    height 100%
    padding 10px
    border-right 1px solid #666
  }
  .right {
    width 70%
    height 100%
    padding 10px
  }
  .cont {
    padding: 10px 0;
  }
}
</style>
