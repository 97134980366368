import fetch from '@/apis/request'
import { formdata2obj } from '@/utils/utils'

export default class apis {
    // 隐患类别
    // 查询树状结构
    static getCategoryTree() {
        return fetch.get(`/pc/hidden-danger-category/getNzTree`)
    }
    // 添加隐患类别
    static addCategory(formData) {
        return fetch.post(`/pc/hidden-danger-category/add`, formData)
    }
    // 修改隐患类别
    static editCategory(formData) {
        return fetch.post(`/pc/hidden-danger-category/update`, formData)
    }
    // 启用隐患类别
    static enableCategory(id) {
        return fetch.post(`/pc/hidden-danger-category/enable?id=${id}`)
    }
    // 作废隐患类别
    static disableCategory(id) {
        return fetch.post(`/pc/hidden-danger-category/disable?id=${id}`)
    }
    // 分页查询隐患类别
    static pageCategory(search) {
        return fetch.get(`/pc/hidden-danger-category/page`,{
            // params: search
            params: formdata2obj(search)
        })
    }

    // 隐患
    // 添加隐患
    static addBank(formData) {
        return fetch.post(`/pc/hidden-danger/add`, formData)
    }
    // 修改隐患
    static editBank(formData) {
        return fetch.post(`/pc/hidden-danger/update`, formData)
    }
    // 启用隐患
    static enableBank(id) {
        return fetch.post(`/pc/hidden-danger/enable?id=${id}`)
    }
    // 作废隐患
    static disableBank(id) {
        return fetch.post(`/pc/hidden-danger/disable?id=${id}`)
    }
    // 分页查询隐患
    static pageBank(search) {
        return fetch.get(`/pc/hidden-danger/page`,{
            // params: search
            params: formdata2obj(search)
        })
    }

    // 检查类型
    // 添加检查类型
    static addCheckType(formData) {
        return fetch.post(`/pc/check-type/add`, formData)
    }
    // 修改检查类型
    static editCheckType(formData) {
        return fetch.post(`/pc/check-type/update`, formData)
    }
    // 启用检查类型
    static enableCheckType(id) {
        return fetch.post(`/pc/check-type/enable?id=${id}`)
    }
    // 作废检查类型
    static disableCheckType(id) {
        return fetch.post(`/pc/check-type/disable?id=${id}`)
    }
    // 分页查询检查类型
    static pageCheckType(search) {
        return fetch.get(`/pc/check-type/page`,{
            // params: search
            params: formdata2obj(search)
        })
    }

    // 责任区域
    // 添加责任区域
    static addDutyArea(formData) {
        return fetch.post(`/pc/duty-area/add`, formData)
    }
    // 修改责任区域
    static editDutyArea(formData) {
        return fetch.post(`/pc/duty-area/update`, formData)
    }
    // 启用责任区域
    static enableDutyArea(id) {
        return fetch.post(`/pc/duty-area/enable?id=${id}`)
    }
    // 作废责任区域
    static disableDutyArea(id) {
        return fetch.post(`/pc/duty-area/disable?id=${id}`)
    }
    // 分页查询责任区域
    static pageDutyArea(search) {
        return fetch.get(`/pc/duty-area/page`,{
            params: formdata2obj(search)
        })
    }
    // 单个查询责任区域
    static DutyAreaFindById(id) {
        return fetch.get(`/pc/duty-area/findById`,{
            params: {
                id
            }
        })
    }
    // 查询所有正常的分包单位
    static allSubcontractUnit(departmentCode) {
        return fetch.get(`/pc/subcontract-unit/findEnable`,{
            params: {
                departmentCode
            }
        })
    }

    // 分包单位
    // 添加分包单位
    static addSubcontractUnit(formData) {
        return fetch.post(`/pc/subcontract-unit/add`, formData)
    }
    // 修改分包单位
    static editSubcontractUnit(formData) {
        return fetch.post(`/pc/subcontract-unit/update`, formData)
    }
    // 启用分包单位
    static enableSubcontractUnit(id) {
        return fetch.post(`/pc/subcontract-unit/enable?id=${id}`)
    }
    // 作废分包单位
    static disableSubcontractUnit(id) {
        return fetch.post(`/pc/subcontract-unit/disable?id=${id}`)
    }
    // 分页查询分包单位
    static pageSubcontractUnit(search) {
        return fetch.get(`/pc/subcontract-unit/page`,{
            // params: search
            params: formdata2obj(search)
        })
    }
    // 根据组织机构code和角色id获得所需要的的用户
    static findByDepartmentId(code) {
        if (typeof(code)=='string'){
            return fetch.get(`/pc/organization/findByDepartmentId?departmentCode=${code}`)
        }else{
            return fetch.get(`/pc/organization/findByDepartmentId`,{
                params: code
            })
        }
    }
}
